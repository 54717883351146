<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import {minLength, required} from "vuelidate/lib/validators";
import Master from "@/apis/Master";
import Swal from "sweetalert2";
import Vue from "vue";

/**
 * Orders Component
 */
export default {
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      title: "COLLECTIONS",
      items: [
        {
          text: "DASHBOARD",
          href: "/"
        },
        {
          text: "COLLECTIONS",
          active: true
        }
      ],
      checkbox:[],
      isCheckAll:false,
      collectionData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      selected:"true",
      fields: [
        { key: "checkbox", sortable: false, label: ""},
        { key: "image", sortable: false, label: "IMAGE",  thStyle: { color: "black", "font-size":"16px"} },
        { key: "name", sortable: true, label: "NAME",  thStyle: { color: "black", "font-size":"16px"} },
        { key: "collectionType", sortable: true, label: "COLLECTION TYPE",  thStyle: { color: "black", "font-size":"16px"} },
        { key: "status", sortable: true, label: "STATUS",  thStyle: { color: "black", "font-size":"16px"} },
        { key: "action", label: "ACTION", sortable: false,  thStyle: { color: "black", "font-size":"16px"}}
      ],
      collections: {
        name: "",
        image: "",
        collectionType: "",
        status: "1"
      },
      editCollections:{
        name: "",
        image: "",
        collectionType: "",
        status: "1"
      },
      submitted: false,
      editSubmitted: false,
      showmodal: false,
      editData: [],
      successMessage: "",
      errorMessage: "",
      imagePath:"",
      preview:"",
      editshowmodal: false
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.collectionData.length;
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.collectionData.length;

    Master.collectionList({
      trash: 0
    }).then((res) => {
      console.log(res)
      this.collectionData = res.data.data;
    })
  },
  validations: {
    collections: {
      name: { required, minLength: minLength(3) },
      status: { required },
      collectionType: { required }
    },
    editCollections:{
      name: { required, minLength: minLength(3) },
      status: { required },
      collectionType: { required }
    }
  },
  methods: {
    /**
     * Search the table data with search input
     */
    checkAll: function(){
      console.log(this.perPage)
      this.isCheckAll = !this.isCheckAll;
      this.checkbox = [];
      if(this.isCheckAll){ // Check all
        for (var key in this.collectionData) {
          console.log(key,this.collectionData[key].id)
          this.checkbox.push(this.collectionData[key].id);
        }
      }
    },

    async bulkActive(){
      if (this.checkbox[0] == null){
        alert("Please select atleast one checkbox")
      }else {
        Master.collectionBulkActive({
          ids:this.checkbox,
          status: 1
        }).then((res) => {
          // this.successMessage = res.data.message;
          Vue.swal({
            position: "center",
            icon: "success",
            title: ""+res.data.message+"",
            showConfirmButton: false,
            timer: 1500
          });
          this.checkbox = []
          this.isCheckAll = false
          Master.collectionList({
            trash: 0
          }).then((res) => {
            console.log(res)
            this.collectionData = res.data.data;
          })
        })
      }

    },
    async bulkInactive(){
      if (this.checkbox[0] == null){
        alert("Please select atleast one checkbox")
      }else {
        Master.collectionBulkInActive({
          ids: this.checkbox,
          status: 0
        }).then((res) => {
          // this.successMessage = res.data.message;
          Vue.swal({
            position: "center",
            icon: "success",
            title: ""+res.data.message+"",
            showConfirmButton: false,
            timer: 1500
          });
          this.checkbox = []
          this.isCheckAll = false
          Master.collectionList({
            trash: 0
          }).then((res) => {
            console.log(res)
            this.collectionData = res.data.data;
          })
        })
      }
    },

    async bulkDelete(){
      if (this.checkbox[0] == null){
        alert("Please select atleast one checkbox")
      }else {
        if (confirm('Are you sure you want to delete this item?')) {
          Master.collectionBulkDelete({
            ids: this.checkbox,
            trash: 0
          }).then((res) => {
            // this.successMessage = res.data.message;
            Vue.swal({
              position: "center",
              icon: "success",
              title: ""+res.data.message+"",
              showConfirmButton: false,
              timer: 1500
            });
            this.checkbox = []
            this.isCheckAll = false
            Master.collectionList({
              trash: 0
            }).then((res) => {
              console.log(res)
              this.collectionData = res.data.data;
            })
          })
        }
      }
    },
    editModal(data){
      this.editshowmodal = true
      Master.collectionDetails({
        id:data
      }).then((res) => {
        this.editCollections.id = res.data.data.id
        this.editCollections.name = res.data.data.name
        this.preview = res.data.data.image
        this.editCollections.collectionType = res.data.data.collectionType
        this.editCollections.status = res.data.data.status
        console.log(res)
      })
    },
    onRowSelected(item){
      console.log(item[0].id);
    },
    sliderImageUpload(event){
      this.collections.image = event.target.files[0]
      var input = event.target;
      if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.preview = e.target.result;
        }
        if(event.target.files[0]) {
          reader.readAsDataURL(input.files[0]);
        }else {
          this.preview = ""
        }
        this.editCollections.image = input.files[0];

      }


      console.log(event)
    },

    async deleteSlider(data){
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.value) {
          Master.collectionDelete(
              {
                trash: 0,
                ids: [data]
              }).then(res => {
            console.log(res)
            Swal.fire("Deleted!", "Your data has been deleted.", "success");
            // this.successMessage = res.data.message;
            Master.collectionList({
              trash: 0
            }).then((res) => {
              console.log(res)
              this.collectionData = res.data.data;
            })
          })
        }
      });
    },

    async handleSubmit() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.collections.$invalid) {
        return;
      } else {
        const fd = new FormData();
        const config = { headers: { "Content-Type": "multipart/form-data" } };
        fd.append("image",this.collections.image);
        fd.append('data',JSON.stringify({
              "name" : this.collections.name,
              "collectionType" : this.collections.collectionType,
              "status" : this.collections.status
            }
        ));
        Master.collectionAdd(fd,config).then((res) => {
          console.log(res)
          Vue.swal({
            position: "center",
            icon: "success",
            title: ""+res.data.message+"",
            showConfirmButton: false,
            timer: 1500
          });
          // this.successMessage = res.data.message;

          Master.collectionList({
            trash: 0
          }).then((res) => {
            console.log(res)
            this.collectionData = res.data.data;
          })
          this.showmodal = false;
        }).catch((err) => {
          console.log(err)
          // this.errorMessage = err.response.data.error.name[0];
          this.$bvToast.toast(""+err.response.data.error.name[0]+"", {
            title: `Error Message`,
            variant: 'danger',
            solid: true
          });
          this.showmodal = false;
        })

      }
      this.submitted = false;
      this.collections.status = 1;
      this.collections.name = "";
      this.collections.collectionType = "";
      this.editCollections.image = "";
      this.collections.image = "";
      this.preview = "";
    },

    handleUpdate(){
      this.editSubmitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.editCollections.$invalid) {
        return;
      } else {
        const fd = new FormData();
        const config = { headers: { "Content-Type": "multipart/form-data" } };
        fd.append("image",this.editCollections.image);
        fd.append('data',JSON.stringify({
              "id" : this.editCollections.id,
              "name" : this.editCollections.name,
              "collectionType" : this.editCollections.collectionType,
              "status" : this.editCollections.status
            }
        ));
        Master.collectionUpdate(fd,config).then((res) => {
          console.log(res)
          // this.successMessage = res.data.message;
          Vue.swal({
            position: "center",
            icon: "success",
            title: ""+res.data.message+"",
            showConfirmButton: false,
            timer: 1500
          });
          Master.collectionList({
            trash: 0
          }).then((res) => {
            console.log(res)
            this.collectionData = res.data.data;
          })
          this.editshowmodal = false;
        }).catch((err) => {
          console.log(err)
          // this.errorMessage = err.response.data.errors;
          this.$bvToast.toast(""+err.response.data.errors+"", {
            title: `Error Message`,
            variant: 'danger',
            solid: true
          });
          this.editshowmodal = false;
        })
      }
      this.editSubmitted = false;
      this.preview = "";
      this.imagePath = "";
      this.editCollections.image = "";
      this.collections.image = "";
    },
    hideModal() {
      this.collections.name = "";
      this.editCollections.image = "";
      this.collections.image = "";
      this.preview = "";
      this.imagePath = "";
      this.submitted = false;
      this.showmodal = false;
    },
    hideEditModal() {
      this.editSubmitted = false;
      this.editshowmodal = false;
      this.preview = "";
      this.imagePath = "";
      this.editCollections.image = "";
      this.collections.image = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  }
};
</script>

<template>
  <Layout>

    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body" style="text-transform:uppercase !important;">
            <div class="btn-toolbar">
              <a href="javascript:void(0);" class="btn btn-primary mb-2 mr-1"  @click="showmodal = true">
                <i class="mdi mdi-plus mr-2"></i> Add
              </a>
              <a href="javascript:void(0);" class="btn btn-outline-success mb-2 mr-1" @click="bulkActive">
                <i class="mdi mdi-check mr-2"></i> Active
              </a>
              <a href="javascript:void(0);" class="btn btn-outline-danger mb-2 mr-1" @click="bulkInactive">
                <i class="mdi mdi-cancel mr-2"></i> In-Active
              </a>
              <a href="javascript:void(0);" class="btn btn-danger mb-2 mr-1" @click="bulkDelete">
                <i class="mdi mdi-trash-can mr-2"></i> Delete
              </a>
            </div>
            <b-alert show dismissible variant="success" v-if="successMessage">
              <i class="mdi mdi-check-all mr-2"></i>{{successMessage}}
            </b-alert>
            <b-alert show dismissible variant="danger" v-if="errorMessage">
              <i class="mdi mdi-block-helper mr-2"></i>{{ errorMessage }}
            </b-alert>
            <b-tabs nav-class="nav-tabs-custom">
              <div class="row mt-4">
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                      Show&nbsp;
                      <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                    </label>
                  </div>
                </div>
                <!-- Search -->
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                    <label class="d-inline-flex align-items-center">
                      Search:
                      <b-form-input
                          v-model="filter"
                          type="search"
                          class="form-control form-control-sm ml-2"
                      ></b-form-input>
                    </label>
                  </div>
                </div>
                <!-- End search -->
              </div>
              <div class="table-responsive">
                <b-table
                    class="table-centered"
                    :items="collectionData"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    show-empty
                    striped
                    sort-icon-left
                    selectable  @row-selected="onRowSelected"
                    select-mode="single"
                    primary-key="id"



                >
                  <template v-slot:head(checkbox)>
                    <div class="form-check mb-1">
                      <input
                          type="checkbox"
                          class="form-check-input"
                          v-model="isCheckAll"
                          :id="`customercheck`"
                          @click='checkAll()'
                      />
                      <label class="form-check-label" :for="`customercheck`"></label>
                    </div>
                  </template>
                  <template  v-slot:cell(image) = "data">
                    <img
                        :src="data.item.image"
                        alt="collection-img"
                        title="collection-img"
                        class="avatar-md"
                    />
                  </template>
                  <template v-slot:cell(collectionType)="row">
                    <div v-if="row.value == 1"
                         class="badge font-size-12 "
                    >NEW ARRIVAL</div>
                    <div v-if="row.value == 2"
                         class="badge font-size-12 "
                    >BEST SELLING</div>
                    <div v-if="row.value == 3"
                         class="badge font-size-12 "
                    >SHOP BY STYLE</div>
                  </template>
                  <template v-slot:cell(status)="row">
                    <div v-if="row.value == 1"
                         class="badge font-size-12 badge-soft-success"
                    >ACTIVE</div>
                    <div v-if="row.value == 0"
                         class="badge font-size-12 badge-soft-danger"
                    >IN-ACTIVE</div>
                  </template>
                  <template v-slot:cell(checkbox)="row">
                    <div class="form-check mb-3">
                      <input
                          type="checkbox"
                          class="form-check-input"
                          v-model="checkbox"
                          :id="`customercheck${row.index}`"
                          :value="row.item.id"
                      />
                      <label class="form-check-label" :for="`customercheck${row.index}`">&nbsp;</label>
                    </div>
                  </template>
                  <template v-slot:cell(name)="row">
                    <p class="badge font-size-12">
                      {{row.item.name}}
                    </p>
                  </template>

                  <template v-slot:cell(action) = "data">
                    <a
                        href="javascript:void(0);"
                        class="mr-3 text-primary"
                        @click="editModal(data.item.id)"
                        v-b-tooltip.hover
                        title="Edit"
                    >
                      <i class="mdi mdi-pencil font-size-18"></i>
                    </a>
                    <a
                        href="javascript:void(0);"
                        class="text-danger"
                        @click="deleteSlider(data.item.id)"
                        v-b-tooltip.hover
                        title="Delete"
                    >
                      <i class="mdi mdi-trash-can font-size-18"></i>
                    </a>
                  </template>
                </b-table>
              </div>
              <div class="row">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                          aria-controls
                      >
                      </b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
              <!--              </b-tab>-->
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Add -->
    <b-modal
        id="modal-1"
        v-model="showmodal"
        title="ADD COLLECTION"
        title-class="text-dark font-18"
        hide-footer
        @close="hideModal"
    >

      <form @submit.prevent="handleSubmit">

            <div class="form-group">
              <label for="name">NAME *</label>
              <input
                  id="name"
                  v-model="collections.name"
                  type="text"
                  class="form-control"
                  placeholder="ENTER COLLECTION TITLE"
                  :class="{ 'is-invalid': submitted && $v.collections.name.$error }"
                  style="text-transform: uppercase !important;"
              />
              <div v-if="submitted && $v.collections.name.$error" class="invalid-feedback">
                <span v-if="!$v.collections.name.required">NAME IS REQUIRED</span>
                <span v-if="!$v.collections.name.minLength">NAME SHOULD BE ATLEAST 3 CHARACTER</span>
              </div>
            </div>

        <div class="form-group">
          <label for="Collectiontype">COLLECTION TYPE *</label>
          <select class="form-control"
                  id="Collectiontype"
                  v-model="collections.collectionType"
                  :class="{ 'is-invalid': submitted && $v.collections.collectionType.$error }">
            <option value="">COLLECTION TYPE</option>
            <option value="1">NEW ARRIVAL</option>
            <option value="2">BEST SELLING</option>
            <option value="3">SHOP BY STYLE</option>
          </select>
          <div
              v-if="submitted && !$v.collections.collectionType.required"
              class="invalid-feedback"
          >COLLECTION TYPE IS REQUIRED</div>
        </div>

        <div class="form-group">
        <label for="position">STATUS</label>
        <select class="form-control"
                id="position"
                v-model="collections.status"
                :class="{ 'is-invalid': submitted && $v.collections.status.$error }">
          <option value="1">ACTIVE</option>
          <option value="0">IN-ACTIVE</option>
        </select>
        <div
            v-if="submitted && !$v.collections.status.required"
            class="invalid-feedback"
        >Status is required</div>
      </div>

        <div class="form-group">
          <label for="code">IMAGE <span style="font-size: 12px" v-if="collections.collectionType == 2">(640 x 492)</span><span style="font-size: 12px" v-else-if="collections.collectionType == 3">(352 x 352)</span><span style="font-size: 12px" v-else-if="collections.collectionType == 1">(640 x 492)</span></label>
          <br/>
          <input
              id="code"
              type="file"
              @change="sliderImageUpload"
          />
          <!--          Image Preview-->
          <div class="border p-2 mt-3">
            <template v-if="preview">
              <img :src="preview" class="avatar-md"/>
            </template>
          </div>
        </div>



        <div class="text-right">
          <button type="submit" class="btn btn-success">SAVE</button>
          <b-button class="ml-1" variant="danger" @click="hideModal">CANCEL</b-button>
        </div>
      </form>
    </b-modal>
    <!-- end modal -->


    <!-- Modal Edit -->
    <b-modal
        id="modal-1"
        v-model="editshowmodal"
        title="EDIT COLLECTION"
        title-class="text-dark font-18"
        hide-footer
        @close="hideEditModal"
    >
      <form @submit.prevent="handleUpdate">
        <div class="form-group">
          <label for="name">NAME *</label>
          <input
              id="name"
              v-model="editCollections.name"
              type="text"
              class="form-control"
              placeholder="ENTER SLIDER TITLE"
              :class="{ 'is-invalid': editSubmitted && $v.editCollections.name.$error }"
              style="text-transform: uppercase !important;"
          />
          <div v-if="editSubmitted && $v.editCollections.name.$error" class="invalid-feedback">
            <span v-if="!$v.editCollections.name.required">SLIDER TITLE IS REQUIRED</span>
            <span v-if="!$v.editCollections.name.minLength">SLIDER TITLE SHOULD BE ATLEAST 3 CHARACTER</span>
          </div>
        </div>
        <div class="form-group">
          <label for="Collectiontype1">COLLECTION TYPE *</label>
          <select class="form-control"
                  id="Collectiontype1"
                  v-model="editCollections.collectionType"
                  :class="{ 'is-invalid': editSubmitted && $v.editCollections.collectionType.$error }">
            <option value="">COLLECTION TYPE</option>
            <option value="1">NEW ARRIVAL</option>
            <option value="2">BEST SELLING</option>
            <option value="3">SHOP BY STYLE</option>
          </select>
          <div
              v-if="editSubmitted && !$v.editCollections.collectionType.required"
              class="invalid-feedback"
          >COLLECTION TYPE IS REQUIRED</div>
        </div>
        <div class="form-group">
          <label for="position">STATUS</label>
          <select class="form-control"
                  id="position"
                  v-model="editCollections.status"
                  :class="{ 'is-invalid': editSubmitted && $v.editCollections.status.$error }">
            <option value="1">ACTIVE</option>
            <option value="0">IN-ACTIVE</option>
          </select>
          <div
              v-if="editSubmitted && !$v.editCollections.status.required"
              class="invalid-feedback"
          >Status is required</div>
        </div>

        <div class="form-group">
          <label for="code">IMAGE <span style="font-size: 12px" v-if="editCollections.collectionType == 2">(640 x 492)</span><span style="font-size: 12px" v-else-if="editCollections.collectionType == 3">(352 x 352)</span><span style="font-size: 12px" v-else-if="editCollections.collectionType == 1">(640 x 492)</span></label>
          <br/>
          <input
              id="code"
              type="file"
              @change="sliderImageUpload"
          />
          <!--          Image Preview-->
          <div class="border p-2 mt-3">
            <p>PREVIEW HEAR:</p>
            <template v-if="preview">
              <img :src="preview" class="avatar-md"/>
            </template>
          </div>

        </div>



        <div class="text-right">
          <button type="submit" class="btn btn-success">UPDATE</button>
          <b-button class="ml-1" variant="danger" @click="hideEditModal">CANCEL</b-button>
        </div>
      </form>
    </b-modal>
    <!-- end modal -->
  </Layout>
</template>